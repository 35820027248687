import { format, parse } from "date-fns";
import { Language } from "../i18n/settings";
import { ReadonlyURLSearchParams } from "next/navigation";
import { RoomProps } from "../components/Room";
import { ReservationInfo } from "../components/RoomSummary/ReservationData";
import {
    BookingType,
    Filter,
    Photo,
    RoomResult,
    SearchUrlProps,
    SimpleSearchUrlProps,
    defaultFilter,
} from "../types/Search";
import {
    SEARCH_URL_DATE_FORMAT,
    defaultEndDate,
    defaultEndDateStr,
    defaultGuests,
    defaultLocation,
    defaultStartDate,
    defaultStartDateStr,
    googleMapsDefaultLocation,
} from "../constants/search";
import { Urls, getUrl } from "../constants/urls";

export const defaultSearchParams = {
    startDate: format(defaultStartDate, SEARCH_URL_DATE_FORMAT),
    endDate: format(defaultEndDate, SEARCH_URL_DATE_FORMAT),
    location: defaultLocation,
    guestCount: defaultGuests.toString(),
};

export const getDefaultSearchParams = () => ({
    startDate: format(defaultStartDate, SEARCH_URL_DATE_FORMAT),
    endDate: format(defaultEndDate, SEARCH_URL_DATE_FORMAT),
    location: defaultLocation,
    guestCount: defaultGuests.toString(),
});

export function generateSearchUrl({ language, ...rest }: SearchUrlProps) {
    const searchUrl = convertToUrlParams(getUrl(Urls.search.index, language!, null, ""), {
        ...rest,
        startDate: format(rest.startDate ?? defaultStartDate, SEARCH_URL_DATE_FORMAT),
        endDate: format(rest.endDate ?? defaultEndDate, SEARCH_URL_DATE_FORMAT),
        location: rest.location?.name ?? defaultLocation,
        guestCount: rest.guestCount ?? defaultGuests,
    });
    return searchUrl;
}

export function extractSearchUrlParameters(urlParams: ReadonlyURLSearchParams | URLSearchParams): SearchUrlProps {
    return {
        guestCount: parseInt(urlParams.get("guestCount") ?? defaultGuests.toString()),
        location: { name: urlParams.get("location") ?? "" },
        startDate: parse(urlParams.get("startDate") ?? defaultStartDateStr, SEARCH_URL_DATE_FORMAT, new Date()),
        endDate: parse(urlParams.get("endDate") ?? defaultEndDateStr, SEARCH_URL_DATE_FORMAT, new Date()),
    };
}

export function extractSimpleSearchUrlParameters(urlParams: SimpleSearchUrlProps): SearchUrlProps {
    return {
        guestCount: parseInt(urlParams.guestCount ?? defaultGuests.toString()),
        location: { name: urlParams.location ?? defaultLocation },
        startDate: parse(urlParams.startDate ?? defaultStartDateStr, SEARCH_URL_DATE_FORMAT, new Date()),
        endDate: parse(urlParams.endDate ?? defaultEndDateStr, SEARCH_URL_DATE_FORMAT, new Date()),
    };
}

export function extractFilterUrlParameters(urlParams: ReadonlyURLSearchParams | URLSearchParams): Filter {
    const getNumberItem = (name: string) => {
        const urlParam = urlParams.get(name);
        if (urlParam) {
            return parseInt(urlParam);
        }
        return undefined;
    };

    return {
        ...defaultFilter,
        amenities: urlParams.getAll("amenities"),
        bookingType:
            urlParams.get("bookingType") === BookingType.Instant ? BookingType.Instant : defaultFilter.bookingType,
        numberOfBathrooms: getNumberItem("numberOfBathrooms"),
        numberOfBedrooms: getNumberItem("numberOfBedrooms"),
        numberOfBeds: getNumberItem("numberOfBeds"),
        roomType: urlParams.getAll("roomType"),
        showOnlyTopPartners: !!urlParams.get("showOnlyTopPartners"),
    };
}

export type RoomSummaryUrlProps = Required<Pick<SearchUrlProps, "startDate" | "endDate" | "guestCount">> & {
    roomId: string;
};

export function extractRoomSummaryFromUrl(urlParams: ReadonlyURLSearchParams | URLSearchParams): RoomSummaryUrlProps {
    if (!urlParams.get("roomId")) {
        throw new Error("Room Id missing in url params!");
    }
    return {
        roomId: urlParams.get("roomId") ?? "",
        startDate: parse(urlParams.get("startDate") ?? defaultStartDateStr, SEARCH_URL_DATE_FORMAT, new Date()),
        endDate: parse(urlParams.get("endDate") ?? defaultEndDateStr, SEARCH_URL_DATE_FORMAT, new Date()),
        guestCount: parseInt(urlParams.get("guestCount") ?? defaultGuests.toString()),
    };
}

export function replaceRoomSummaryInUrl(
    existingParams: ReadonlyURLSearchParams | URLSearchParams,
    data: ReservationInfo,
) {
    const urlParams = new URLSearchParams(existingParams.toString());
    urlParams.set("startDate", format(data.checkIn, SEARCH_URL_DATE_FORMAT));
    urlParams.set("endDate", format(data.checkOut, SEARCH_URL_DATE_FORMAT));
    urlParams.set("guestCount", (data.numGuests as number).toString());
    return urlParams;
}

export function convertToUrlParams(pathName: string, obj: object) {
    const urlParams = new URLSearchParams();
    Object.entries(obj).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((arrayValue) => urlParams.append(key, arrayValue));
        } else if (value) {
            urlParams.append(key, value);
        }
    });
    return `${pathName}?${urlParams.toString()}`;
}

export const generatePhotoUrl = (photo: Photo) => {
    if (photo.url?.startsWith("myroom24")) {
        return photo.url;
    } else if (photo.url?.startsWith("https://cdn")) {
        return photo.url;
    } else {
        return `myroom24_old/rooms/${photo.url ?? photo._id}`;
    }
};

export function convertToListing(resultRoom: RoomResult, lang: Language): RoomProps {
    const monthlyPriceMultiplier = resultRoom.roomCategory.pricing.monthlyPriceMultiplier;
    return {
        roomId: resultRoom.roomCategory._id,
        title: resultRoom.name,
        address: `${resultRoom.address.city}, ${resultRoom.address.country}`,
        isFeatured: resultRoom.roomCategory.featured,
        isInstantBooking: resultRoom.roomCategory.bookingType === BookingType.Instant,
        isSuperHost: false,
        slug: resultRoom.slug,
        fixedSlug: resultRoom.fixedSlug,
        numBaths: resultRoom.roomCategory.numberOfBathrooms,
        numBedrooms: resultRoom.roomCategory.numberOfBedrooms,
        numBeds: resultRoom.roomCategory.numberOfBeds,
        numGuests: resultRoom.roomCategory.maxOccupancy,
        pricePerMonth: resultRoom.roomCategory.pricing.averageMonthly,
        currency: resultRoom.roomCategory.pricing.currency,
        totalOriginal: resultRoom.roomCategory.pricing.totalOriginal,
        totalStay: resultRoom.roomCategory.pricing.totalStay,
        category: resultRoom.roomCategory.name,
        originalPricePerMonth:
            monthlyPriceMultiplier !== 1
                ? resultRoom.roomCategory.pricing.averageMonthly / monthlyPriceMultiplier
                : undefined,
        rating: 4.3,
        lang: lang,
        className: "",
        images: resultRoom.roomCategory.photos.concat(resultRoom.property.photos).map((photo) => {
            return {
                title: photo.description,
                url: generatePhotoUrl(photo),
                _id: photo._id,
            };
        }),
        location: {
            lat: resultRoom.location.coordinates[1],
            lng: resultRoom.location.coordinates[0],
        },
        companyId: resultRoom.property.company._id,
    };
}

export function getCoordinateCenter(rooms: RoomProps[]) {
    if (rooms.length === 0) {
        return googleMapsDefaultLocation;
    }

    return {
        lat: rooms.map((room) => room.location.lat).reduce((acc, curr) => acc + curr, 0) / rooms.length,
        lng: rooms.map((room) => room.location.lng).reduce((acc, curr) => acc + curr, 0) / rooms.length,
    };
}
