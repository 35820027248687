import { Popover } from "@headlessui/react";
import { isBefore, startOfMonth, addMonths, format, addDays } from "date-fns";

import { Ref, forwardRef, useState, useEffect } from "react";
import CalendarMonth from "./CalendarMonth";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { useClickAway } from "@uidotdev/usehooks";
import MonthPicker from "./MonthPicker";
import { useDateFnsLocale } from "@/lib/i18n/client";
import Input, { InputProps } from "../Input";
import { getLanguageData } from "@/lib/i18n/settings";
import { useLanguage } from "@/lib/hooks/useLanguage";
import { usePathname } from "next/navigation";
import { DATEPICKER_DATE_FORMAT_SHORT } from "@/lib/constants/datepicker";

type DatePickerInputProps = {
    className?: string;
    onChangeDate?: (selectedDate: Date) => void;
    afterCloseHandler?: () => void | null;
    minDate?: Date;
    maxDate?: Date;
    showMonthSelector?: boolean;
    arrowDirection?: string;
    isMobileModalOpen?: boolean;
    initialValue: Date | undefined;
    showIcon?: boolean;
    showDoublePicker?: boolean;
    unavailableDays?: Date[];
    allowPast?: boolean;
} & Omit<InputProps, "ref">;

const DatePickerInput = forwardRef<HTMLInputElement, DatePickerInputProps>(
    (
        {
            showIcon = true,
            customHeight,
            className,
            onChangeDate,
            minDate,
            maxDate,
            initialValue,
            arrowDirection,
            showMonthSelector = false,
            isMobileModalOpen = false,
            showDoublePicker = true,
            afterCloseHandler = null,
            unavailableDays,
            allowPast = false,
            ...rest
        },
        ref,
    ) => {
        useDateFnsLocale();
        const lang = useLanguage();
        const pathName = usePathname();
        const locale = getLanguageData(lang);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const formatDate = (date: Date, isShort: boolean): string => {
            // const formatStr = isShort ? DATEPICKER_DATE_FORMAT_SHORT : DATEPICKER_DATE_FORMAT;
            const formatStr = DATEPICKER_DATE_FORMAT_SHORT; // requested by owners on 11.10.2023
            const dateCopy = new Date(date);
            const result = format(dateCopy, formatStr, { locale: locale.dateLocale });
            return result;
        };

        const [selectedDate, setSelectedDate] = useState(Date.now());
        const [value, setValue] = useState<Date | undefined>(initialValue ?? addDays(new Date(), 1));
        const [valueStr, setValueStr] = useState<string>(initialValue ? formatDate(initialValue, false) : "");
        const [valueStrShort, setValueStrShort] = useState<string>(initialValue ? formatDate(initialValue, true) : "");
        const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
        const [isMonthPickerOpen, setIsMonthPickerOpen] = useState<boolean>(false);
        const dateWrapperClass = showMonthSelector || isMobileModalOpen || !showDoublePicker ? "" : "md:w-2/5";

        useEffect(() => {
            onDateSelect(initialValue);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [initialValue]);

        useEffect(() => {
            // Due to the fact that we use query params we need to close on route change
            setIsMonthPickerOpen(false);
        }, [pathName]);

        const closeDatepicker = () => {
            setIsDatePickerOpen(false);
            setIsMonthPickerOpen(false);
            if (afterCloseHandler) afterCloseHandler();
        };
        const refDatepicker = useClickAway(closeDatepicker);

        const moveToNextMonth = () => {
            const nextMonth = addMonths(selectedDate, 1);
            setSelectedDate(nextMonth.getTime());
        };

        const moveToPreviousMonth = () => {
            const prevMonth = addMonths(selectedDate, -1);
            // don't allow to go in the past
            if (isBefore(prevMonth, startOfMonth(Date.now()))) {
                return;
            }
            setSelectedDate(prevMonth.getTime());
        };

        const onDateSelect = (d: Date | undefined) => {
            if (d) {
                setValue(d);
                setValueStr(formatDate(d, false));
                setValueStrShort(formatDate(d, true));
                if (onChangeDate) {
                    onChangeDate(d);
                }
            } else {
                // we have a reset
                setValue(undefined);
                setValueStr("");
                setValueStrShort("");
            }
        };

        const getPopoverClass = (): string => {
            if (showDoublePicker) {
                return showMonthSelector || isMobileModalOpen ? " lg:-left-24 md:w-80 left-0" : " md:-left-52";
            }
            return " md:w-80";
        };

        return (
            <div className={className}>
                <Input
                    ref={ref}
                    {...rest}
                    type="text"
                    onClick={() => {
                        showMonthSelector ? setIsMonthPickerOpen(true) : setIsDatePickerOpen(true);
                    }}
                    value={isMobileModalOpen ? valueStrShort : valueStr}
                    customHeight={customHeight}
                    icon={showIcon ? faCalendar : undefined}
                    iconDirection="left"
                    readOnly
                />

                {(isDatePickerOpen || isMonthPickerOpen) && (
                    <Popover ref={refDatepicker as Ref<HTMLElement>} className={`relative top-1`}>
                        {isMonthPickerOpen && minDate && (
                            <Popover.Panel
                                static
                                className="fixed border-cyan-500 md:absolute px-5 mt-2  md:px-0 md:rounded-md lg:-left-24 left-0 z-10 text-black bg-white w-screen md:w-96"
                            >
                                <div className="flex flex-row border lg:border-gray-300 border-cyan-500 rounded-md">
                                    <MonthPicker
                                        selectedDate={minDate}
                                        onMonthSelect={(m) => {
                                            setSelectedDate(m.getTime());
                                            setIsMonthPickerOpen(false);
                                            setIsDatePickerOpen(true);
                                        }}
                                    />
                                </div>
                            </Popover.Panel>
                        )}

                        {isDatePickerOpen && (
                            <Popover.Panel
                                static
                                className={`fixed border-cyan-500 md:absolute px-5 mt-2 md:px-0 md:rounded-md z-10 text-black ${getPopoverClass()} bg-transparent w-screen ${
                                    isMobileModalOpen && "lg:left-0"
                                }`}
                            >
                                <div
                                    className={`relative flex flex-row border lg:border-gray-300 border-cyan-500 rounded-md ${dateWrapperClass} bg-white`}
                                >
                                    <div className=" absolute -top-1.5 left-2/4 bg-white h-3 w-3 rotate-45 invisible lg:visible border   border-gray-300 border-b-0 border-r-0 "></div>
                                    <CalendarMonth
                                        arrowDirection={arrowDirection}
                                        calendarMonth={selectedDate}
                                        moveToPreviousMonth={moveToPreviousMonth}
                                        moveToNextMonth={moveToNextMonth}
                                        selectedDate={value}
                                        isDouble={!showMonthSelector && !isMobileModalOpen}
                                        isLeft={true}
                                        unavailableDays={unavailableDays}
                                        allowDatesAfter={minDate}
                                        allowDatesBefore={maxDate}
                                        onSelect={(d) => {
                                            onDateSelect(d);
                                            closeDatepicker();
                                        }}
                                        allowPast={allowPast}
                                    />
                                    {!showMonthSelector && !isMobileModalOpen && showDoublePicker && (
                                        <CalendarMonth
                                            calendarMonth={addMonths(selectedDate, 1)}
                                            moveToPreviousMonth={moveToPreviousMonth}
                                            moveToNextMonth={moveToNextMonth}
                                            selectedDate={value}
                                            unavailableDays={unavailableDays}
                                            isDouble={showDoublePicker}
                                            allowDatesAfter={minDate}
                                            allowDatesBefore={maxDate}
                                            onSelect={(d) => {
                                                onDateSelect(d);
                                                closeDatepicker();
                                            }}
                                            allowPast={allowPast}
                                        />
                                    )}
                                </div>
                            </Popover.Panel>
                        )}
                    </Popover>
                )}
            </div>
        );
    },
);

DatePickerInput.displayName = "DatePickerInput";

export default DatePickerInput;
