import { addDays, format } from "date-fns";
import { MINIMUM_NUMBER_OF_STAY_DAYS } from "./datepicker";
import { Coordinates } from "../types/Map";

export const defaultGuests = 1;
export const defaultLocation = "Berlin";
export const SEARCH_URL_DATE_FORMAT = "dd.MM.yy";
export const SEARCH_API_DATE_FORMAT = "yyyy-MM-dd";
export const defaultStartDate = addDays(new Date(), 1);
export const defaultStartDateStr = format(defaultStartDate, SEARCH_URL_DATE_FORMAT);
export const defaultEndDate = addDays(defaultStartDate, MINIMUM_NUMBER_OF_STAY_DAYS);
export const defaultEndDateStr = format(defaultEndDate, SEARCH_URL_DATE_FORMAT);
export const defaultPriceFrom = 0;
export const defaultSort = "price_asc";
export const FILTER_MIN_PRICE = 1;
export const FILTER_MAX_PRICE = 20000;

// Berlin - move this to constants, also prepare for Location pages
export const googleMapsDefaultLocation: Coordinates = {
    lat: 52.52437,
    lng: 13.41053,
};
